import { createStore } from 'vuex';
import getters from './getters';


const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})


const store = createStore({
  state: {
    count: 0,
    scroll :{
      height:0
    }
  },
  mutations: {
  //类似 mvc框架的service层参数是state状态管理
    increment(state) {
      state.count++;
    }
  },
  //类似controller
  actions: {
 // 参数是上下文对象
    increment(context) {
    //类似提交service层
      context.commit('increment');
    }
  },

  modules : modules,
  getters : getters
});

export default store