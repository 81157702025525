import { getToken,getUserInfo,setUserInfo } from '@/utils/auth'


const state = {
    token: getToken(),
    userInfo : getUserInfo()
}

const mutations = {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    // 更新用户信息
    UPDATE_USERINFO: (state, data) => {
        state.userInfo = data
       
    },
}


const actions = {
    // 更新用户信息
    UpdateUserInfo (context, data) {
        context.commit('UPDATE_USERINFO', data)
        setUserInfo(data)
    },
    
   
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }