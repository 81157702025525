import CryptoJS from 'crypto-js'

 // ------------AES-------------
 
 function getAesString(data, key='ytrjytrjytrjytrj', iv='jrtyjrtyjrtyjrty') { //加密
   let keys = CryptoJS.enc.Utf8.parse(key)
   let vis = CryptoJS.enc.Utf8.parse(iv)
   let encrypt = CryptoJS.AES.encrypt(data, keys, {
     iv: vis, //iv偏移量 CBC需加偏移量
    //  mode: CryptoJS.mode.CBC, //CBC模式
     mode: CryptoJS.mode.ECB, //ECB模式
     padding: CryptoJS.pad.Pkcs7 //padding处理
   });
   // debugger
   return encrypt.ciphertext.toString(); //加密完成后，转换成字符串
 }
 
 function getDAesString(encrypted, key='ytrjytrjytrjytrj', iv='jrtyjrtyjrtyjrty') { // 解密
   // eslint-disable-next-line no-redeclare
   var key  = CryptoJS.enc.Utf8.parse(key);
   // eslint-disable-next-line no-redeclare
   var iv   = CryptoJS.enc.Utf8.parse(iv);

   const r = CryptoJS.enc.Hex.parse(encrypted)
      , o = CryptoJS.enc.Base64.stringify(r);

   var decrypted =CryptoJS.AES.decrypt(o,key,{
     iv:iv,
     mode:CryptoJS.mode.ECB,
     padding:CryptoJS.pad.Pkcs7
   });
   return decrypted.toString(CryptoJS.enc.Utf8);
 }

 // AES 对称秘钥加密
 const aes = {
   en: (data) => getAesString(data),
   de: (data) => getDAesString(data)
 };
 // BASE64
 const base64 = {
   en: (data) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data)),
   de: (data) => CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)
 };
 // SHA256
 const sha256 = (data) => {
   return CryptoJS.SHA256(data).toString();
 };
 // MD5
 const md5 = (data) => {
 return CryptoJS.MD5(data).toString();
 };
 
 export default { aes, md5, sha256, base64 };
 