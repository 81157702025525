const getters = {
    sidebar: state => state.app.sidebar,
    size: state => state.app.size,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    // eslint-disable-next-line no-unused-vars
    token: state => state.user.token,
    userInfo : state => state.user.userInfo,

    routes: state => state.routes.routes,
    menus: state => state.routes.menus,
    errorLogs: state => state.errorLog.logs,
  }
  export default getters