import router from "@/router"

const state = {
    routes: [],
    addRoutes: [],
    menus : router.routes
  }
  
const mutations = {
    SET_ROUTES: (state, routes2 = []) => {

        const routes = router.router.getRoutes()
        
        state.addRoutes = typeof(routes2) != undefined ? routes2 : [] 
        state.routes = routes.concat(routes2)
    }
}
const actions = {
    addRouter({ commit }, data) {
      commit('SET_ROUTES', data)
    }
  }

export default {
    namespaced: true,
    state,
    mutations,
    actions
}