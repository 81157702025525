import { createApp } from 'vue'
import App from './App.vue'
import store from "./store"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import provinces from "@/jsons/provinces.json"
// import cities from "@/jsons/cities.json"
// import areas from "@/jsons/areas.json"
// import streets from "@/jsons/streets.json"
import addr from "@/jsons/pcas-code.json";
import VueClipboard from 'vue-clipboard2';
//import VueRouter from 'vue-router';


const app = createApp(App)
app.provide('addr', {
  addr : addr
})

Date.prototype.Format = function (fmt) { //author: meizz 
  var o = {
      "M+": this.getMonth() + 1, //月份 
      "d+": this.getDate(), //日 
      "h+": this.getHours(), //小时 
      "m+": this.getMinutes(), //分 
      "s+": this.getSeconds(), //秒 
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
      "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
  if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// ElementPlusIconsVue.forEach((item,key )=> {
//     app.component(key,item)
// });
app.use(ElementPlus).use(router.router).use(store).use(VueClipboard)
app.mount('#app')
